<template>
  <v-app :style="{background: 'lightGrey'}">
    <v-app-bar app dense hide-on-scroll color="primary" dark>
      <span class="mr-2">Sign Up Clipboard</span>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view class="mb-2 mt-2" :style="{background: 'white'}"/>
    </v-main>
  </v-app>
</template>

<script>
import { firebase, auth } from "@/firebaseInit.js";

export default {
  name: 'App',
  async created() {
    var user = await firebase.getCurrentUser();
    if (!user) {
      await auth.signInAnonymously();
    }
  },

  data: () => ({
    // empty
  }),
};
</script>
