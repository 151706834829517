<template>
  <v-container>
    <v-snackbar :color="snackbar.color" v-model="snackbar.show" :timeout="3000" >
      {{ snackbar.message }}
    </v-snackbar>
    <p>
      <v-icon x-large>mdi-clipboard-text-multiple-outline</v-icon>
    </p>
    <v-form ref="mainform" v-model="mainform.valid" @submit.prevent="saveSignup($event)" lazy-validation >
      <v-text-field dense label="Title for this sign-up sheet" v-model.trim="signup.name" />
      <v-textarea dense rows=2 label="Longer description for this sign-up sheet" v-model.trim="signup.description" :counter="500" :rules="description_rules" />
      <v-text-field dense label="Your name" v-model.trim="signup.owner_user_name" />
      <v-btn :disabled="!mainform.valid" color="secondary" class="mb-1 mr-2" @click="saveSignup()">Save</v-btn>
      Your signup link is {{environment.signUpUrl}}
    </v-form>
    <v-simple-table class="mt-3" :dense="false">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-subtitle-1 font-weight-bold signup-column">
              Slot
            </th>
            <th class="text-subtitle-1 font-weight-bold signup-column">
              Number Needed
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="slot in slots" :key="slot.id">
            <td>
              <p class="font-weight-bold">{{ slot.description }}</p>
            </td>
            <td>
              <p class="font-weight-bold">{{ slot.number_needed }}</p>
            </td>
            <td>
              <v-btn x-small @click="removeSlot(slot)">Delete</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-form ref="slotform" v-model="form.valid" @submit.prevent="saveSlot($event)" lazy-validation >
      <v-text-field label="Name of slot" v-model.trim="slot.description" />
      <v-text-field  label="Number needed" type="number" v-model.number="slot.number_needed"/>
      <v-btn :disabled="!form.valid" color="secondary" class="mb-1 mr-2" @click="saveSlot()">Add</v-btn>
    </v-form>
  </v-container>
</template>

<style scoped>
.signup-column {
  width: 50%;
}
</style>

<script>
import { firebase, db } from "@/firebaseInit.js";

export default {
  data () {
    return {
      signup: {
        name: "Loading...",
      },
      signup_id: null,
      slots: [],
      slot: {},
      mainform: {
        valid: true,
      },
      form: {
        valid: true,
      },
      snackbar: {
        show: false,
        message: null,
        color: "success"
      },
      environment: { signUpUrl : '/' },
      required_field_rules: [
        v => !!v || 'Required',
      ],
      description_rules: [
        v => (!v || v.length <= 500) || 'Must be 500 characters or less',
      ],
    }
  },
  async created() {
    const current_user = await firebase.getCurrentUser();

    if (this.$route.params.id) {
      this.environment.signUpUrl = window.location.toString().replace('/edit/','/signup/');
      this.signup_id = this.$route.params.id;
      const mainDocRef = db.collection("signups").doc(this.$route.params.id);
      const doc = await mainDocRef.get();
      if (doc.exists) {
        this.signup = doc.data();
        if (this.signup.owner_user_id != current_user.uid) {
          this.$router.push({ name: 'signup', params: { id: this.signup_id } })
        }
      } else {
        this.signup = { name: "** Sorry, that sign-up sheet was not found **" }
      }
    } else {
      this.signup = {
        owner_user_id: current_user.uid,
        modified_date: firebase.firestore.FieldValue.serverTimestamp(),
      }
      const doc = await db.collection("signups").add(this.signup);
      this.signup_id = doc.id;
      this.environment.signUpUrl = window.location.toString().replace('/edit','/signup') + (!window.location.toString().endsWith('/') ? '/' : '') + this.signup_id;
    }

    const slots = db.collection("signups").doc(this.signup_id).collection("slots").orderBy("position");
    this.$bind('slots', slots);
  },
  methods: {
    saveSignup(){
      if (!this.$refs.mainform.validate()) return;

      this.signup.modified_date = firebase.firestore.FieldValue.serverTimestamp();
      db.collection("signups").doc(this.signup_id).update(this.signup).then( () => {
        this.snackbar.show = true;
        this.snackbar.message = "Saved!";
        this.snackbar.color = "success";
      }).catch(this.errorSnackBar);
    },
    saveSlot(){
      if (!this.slot.description) return;
      this.slot.modified_date = firebase.firestore.FieldValue.serverTimestamp();
      this.slot.position = this.slots.length * 100;
      db.collection("signups").doc(this.signup_id).collection("slots").add(this.slot).then( () => {
        this.slot = {};
      }).catch(this.errorSnackBar);
    },
    async removeSlot(slot){
      await db.collection("signups").doc(this.signup_id).collection("slots").doc(slot.id).delete();
    },
    errorSnackBar(error) {
      this.snackbar.show = true;
      this.snackbar.message = error;
      this.snackbar.color = "error";
    },
  }
}
</script>