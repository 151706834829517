const config = {
    emulator: {
      enable: !!process.env.VUE_APP_FIRESTORE_EMULATOR_HOST,
      host: process.env.VUE_APP_FIRESTORE_EMULATOR_HOST
    },
    app: {
      name: parse(process.env.VUE_APP_NAME, "Signup"),
      version: parse(process.env.PACKAGE_VERSION, "0.0.0"),
    },
    firebase: {
      projectId: parse(process.env.VUE_APP_FIREBASE_projectId, "signup-200"),
      appId: parse(process.env.VUE_APP_FIREBASE_appId, "1:1023560892337:web:0179896af559b558590790"),
      locationId: "us-central",
      apiKey: parse(process.env.VUE_APP_FIREBASE_apiKey, "AIzaSyChCqNzkcjP1DHaml0FsjFM2oxpAMF01Xs"),
      messagingSenderId: parse(process.env.VUE_APP_FIREBASE_messagingSenderId, "1023560892337"),
      measurementId: parse(process.env.VUE_APP_FIREBASE_measurementId, "G-JK663W3R04"),
    }
  }
  
  config.firebase.databaseURL = `https://${config.firebase.projectId}.firebaseio.com`;
  config.firebase.storageBucket = `${config.firebase.projectId}.appspot.com`;
  config.firebase.authDomain = `${config.firebase.projectId}.firebaseapp.com`;
  
  function parse (value, fallback) {
    if (typeof value === 'undefined') {
      return fallback
    }
  
    switch (typeof fallback) {
      case 'boolean' :
        return !!JSON.parse(value)
      case 'number' :
        return JSON.parse(value)
      default :
        return value
    }
  }
  
  export {
    config
  }
  
  export default {
    install (Vue) {
      Vue.appConfig = config
      Vue.prototype.$appConfig = config
   }
  }