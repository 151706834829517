import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import Vue from 'vue'
import { firestorePlugin } from 'vuefire'
import { config } from '@/config'
// import "firebase/analytics";
// Vue.prototype.$analytics = firebase.analytics();

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}
const db = firebase.firestore();

if (config.emulator.enable) {
  console.warn('Using emulator, not real firestore')
  db.settings({
    host: config.emulator.host,
    ssl: false
  });
}

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      //console.log(user.uid)
      resolve(user);
    }, reject);
  }
)};

Vue.use(firestorePlugin)
const auth = firebase.auth();
export { firebase, db, auth };