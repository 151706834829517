import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#334d4d',
                secondary: '#527a7a',
                // accent: '',
                // error: '',
                // warning: '',
                // info: '',
                // success: '',
            }
        }
    },
    icons: {
        iconfont: 'mdi'
    }
});
