<template>
  <v-container>
    <v-snackbar :color="snackbar.color" v-model="snackbar.show" :timeout="3000" >
      {{ snackbar.message }}
    </v-snackbar>
    <h2 class="mb-3">
        <v-icon x-large>mdi-clipboard-text-multiple-outline</v-icon>
        <span class="ml-3">{{ signup.name }}</span>
    </h2>
    <h4 class="mt-3">
        {{ signup.description }}
    </h4>
    <p v-show="signup.owner_user_name" class="mt-3 font-weight-thin">
        Created by {{ signup.owner_user_name }}
    </p>
    <v-simple-table class="mt-3" :dense="false" v-if="slots.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-subtitle-1 font-weight-bold slot-column">
              {{ signup.column_heading || "Available" }}
            </th>
            <th class="text-subtitle-1 font-weight-bold signup-column">
              Sign Up
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="slot in computedSlots" :key="slot.id">
            <td>
              <p class="font-weight-bold">{{ slot.description }} <span v-if="slot.number_needed > 1"> ({{ slot.number_needed }})</span></p>
            </td>
            <td>
              <p class="mt-2 mb-2" :style="{color: 'green'}" v-show="slot.volunteers" v-for="(v,k) in slot.volunteers" :key="k">
                <span class="font-weight-bold">{{ v.name }}</span>
                <span v-if="v.number" class="font-weight-bold">&nbsp;({{v.number}})</span>
                <v-btn x-small depressed outlined text v-if="k == volunteer.user_id" class="ml-1" @click="remove(v.volunteer_id)">x</v-btn>
                <br><span class="font-italic">{{v.comment}}</span>
              </p>
              <v-btn class="mt-2 mb-2" v-show="!form.open && !slot.show && (!slot.volunteers || (slot.number_needed > slot.total_volunteered && !slot.volunteers[volunteer.user_id]))" @click="showSlot($event,slot)">
                <v-icon class="mr-2">mdi-hand-back-right-outline</v-icon>
                <span>Sign Up</span>
              </v-btn>
              <v-form ref="form" v-if="slot.show" v-model="form.valid" @submit.prevent="save(slot)" lazy-validation >
                <v-text-field label="Enter your name" v-model.trim="volunteer.name" :rules="required_field_rules" />
                <v-text-field v-if="slot.number_needed > 1" label="How Many" type="number" v-model.number="volunteer.number"/>
                <v-text-field label="Comments" v-model.trim="volunteer.comment" />
                <v-btn :disabled="!form.valid" color="secondary" class="mb-1 mr-2" @click="save(slot)">Save</v-btn>
                <v-btn color="secondary" class="mb-1" @click="cancel(slot)">Cancel</v-btn>
              </v-form>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<style scoped>
.slot-column {
  width: 40%;
}
.signup-column {
  width: 60%;
}
</style>

<script>
import { firebase, db, auth } from "@/firebaseInit.js";

export default {
  data () {
    return {
      signup: {
        name: "Loading...",
      },
      slots: [],
      volunteer: {},
      form: {
        valid: true,
        open: false,
      },
      snackbar: {
        show: false,
        message: null,
        color: "success"
      },
      required_field_rules: [
        v => !!v || 'Required',
      ],
    }
  },
  computed: {
    computedSlots() {
      let returnSlots = [];
      for (let slot of this.slots) {
        let tot = 0;
        let size = 0;
        for( let key in slot.volunteers) {
          size += 1;
          if (slot.volunteers[key].number) {
            tot += slot.volunteers[key].number
          }
        }
        let newSlot = {
            id: slot.id,
            number_needed: slot.number_needed,
            description: slot.description,
            total_volunteered: tot || size,
            volunteers: slot.volunteers,
          };
        returnSlots.push(newSlot);
      }
      return returnSlots;
    }
  },
  async created() {
    auth.onAuthStateChanged(user => {
      if (!user) return;
      this.volunteer.user_id = user.uid;
      this.volunteer.user_email = user.email;
      this.volunteer.user_email_verified = user.emailVerified;
    });

    // give it a head start on spinning up the container to run the firestore function
    db.collection("volunteers").doc("warmer").set({ name: "warmer", slot_id: "warmer", signup_id: "warmer", modified_date: firebase.firestore.FieldValue.serverTimestamp() })

    const mainDocRef = db.collection("signups").doc(this.$route.params.id);
    this.$bind('signup', mainDocRef);
    mainDocRef.get().then(doc => {
      if (doc.exists) {
        this.volunteer.signup_id = this.$route.params.id
      } else {
        this.signup = { name: "** Sorry, that sign-up sheet was not found **" }
        this.volunteer = {}
      }
    })

    const slots = mainDocRef.collection('slots').orderBy('position');
    this.$bind('slots', slots);
  },
  methods: {
    showSlot(event,slot) {
      slot.show = true;
      this.form.open = true;
      this.volunteer.slot_id = slot.id;
    },
    save(slot){
      if (!this.$refs.form[0].validate()) return;

      this.volunteer.modified_date = firebase.firestore.FieldValue.serverTimestamp();
      db.collection("volunteers").add(this.volunteer).then( () => {
        this.snackbar.show = true;
        this.snackbar.message = "Saved!";
        this.snackbar.color = "success";
        slot.show = false;
        this.form.open = false;
      }).catch((error) => {
        this.snackbar.show = true;
        this.snackbar.message = error;
        this.snackbar.color = "error";
      });
    },
    cancel(slot){
      slot.show = false;
      this.form.open = false;
    },
    async remove(volunteerId){
      await db.collection("volunteers").doc(volunteerId).delete();
      this.snackbar.show = true;
      this.snackbar.message = "Removed";
      this.snackbar.color = "info";
    },
  }
}
</script>