import Vue from 'vue'
import VueRouter from 'vue-router'
import SignUp from '../views/signup.vue';
import Edit from '../views/edit.vue';
import {firebase} from '@/firebaseInit.js'
const baseTitle = 'Sign Up Clipboard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signup/:id',
    name: 'signup',
    meta: { 
      requiresAuth: false,
      title: baseTitle + ' - Sign Up'
    },
    component: SignUp
  },
  {
    path: '/edit/:id',
    name: 'edit',
    meta: { 
      requiresAuth: false,
      title: baseTitle + ' - Edit'
    },
    component: Edit
  },
  {
    path: '/edit/',
    name: 'create',
    meta: { 
      requiresAuth: false,
      title: baseTitle + ' - Create'
    },
    component: Edit
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, _from, next) => {
  document.title = to.meta.title || baseTitle
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !await firebase.getCurrentUser()){
    next({ name: 'signin', query: { dest: to.name } });
  } else {
    next();
  }
});

export default router